import React, { useMemo } from 'react'

import PanelLinks from './PanelLinks/PanelLinks'

const HomePageLinks = ({ post, showNumbers = true }) => {
  const links = useMemo(() => {
    if (post?.children?.nodes?.length)
      return post.children.nodes.filter(page => page.menuOrder > 2).sort((a, b) => a.menuOrder > b.menuOrder ? 1 : -1)
    return []
  }, [post])
  return <PanelLinks links={links} showNumbers={showNumbers} />
}

export default HomePageLinks
