import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import HomePageLinks from '../components/links/HomePageLinks'
import { Article } from '../components/common.styled'

const HomePageTemplate = ({data: {post}}) => {
  return <Layout lang={post.lang}>
    <Seo title={post.title} />
    <Article
        className='page home'
        itemScope
        itemType='http://schema.org/Article'
      >
        <section itemProp='articleBody'>
          <HomePageLinks post={post} showNumbers={post?.homePageTemplateFields?.homePageShowNumbers} />
          {post.content && parse(post.content)}
        </section>
    </Article>
  </Layout>
}

export default HomePageTemplate

export const pageQuery = graphql`
  query HomePageTemplate(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      content
      title
      lang: slug
      homePageTemplateFields {
        homePageShowNumbers
      }
      children: wpChildren {
        nodes {
          ... on WpPage {
            title
            uri
            menuOrder
          }
        }
      }
    }
  }
`
